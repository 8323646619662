<div appPanel (closePanelEmitter)="close(false)"></div>

<div class="modal_content">
  <button class="mat-modal-closer" mat-dialog-close>
    <svg-icon
      src="assets/images/svg-icons/icon-times-light.svg"
      [svgStyle]="{ 'width.px': '18' }"
    ></svg-icon>
  </button>

  <div class="modal-inside bg-theme-white">
    <div class="modal-circles">
      <div class="background -red"></div>
      <div class="circle-1"></div>
      <div class="circle-2"></div>
      <div class="circle-3"></div>

      <img src="assets/images/illustrations/warning.png" alt="" />
    </div>

    <form [formGroup]="confirmForm" (ngSubmit)="close(true)" class="modal-text">
      <div class="modal-picto -picto-svg -red">
        <svg-icon
          src="assets/images/svg-icons/icon-warning.svg"
          [svgStyle]="{ 'width.px': '30' }"
        ></svg-icon>
      </div>

      <div class="modal-title -red">
        {{ data.title }}
      </div>

      <div class="modal-theme">
        <p [innerHTML]="data.content"></p>

        <ng-container *ngIf="data.textToInput">
          <p appCopyRestricted>
            <ng-container i18n
              >Entrez ci-dessous le texte suivant pour confirmer&nbsp;:&nbsp;</ng-container
            >
            <strong>{{ data.textToInput }}</strong>
          </p>

          <form class="form-v2-nnki max-w-[300px] mx-auto classic-grid">
            <app-form-field-text
              [label]="data.textToInput"
              controlName="text"
              [group]="confirmForm"
            ></app-form-field-text>

            <app-form-field-password
              *ngIf="data.isPasswordProtected"
              i18n-label
              label="Mot de passe"
              controlName="password"
              [group]="confirmForm"
              [autocomplete]="'current-password'"
            ></app-form-field-password>
          </form>
        </ng-container>
      </div>

      <div class="modal-buttons">
        <button
          matRipple
          [matRippleCentered]="false"
          [matRippleUnbounded]="true"
          [matRippleRadius]="50"
          type="submit"
          class="btn-nnki btn-nnki-red btn-nnki-uppercase"
        >
          {{ data.buttonText }}
        </button>
      </div>

      <div class="h-[60px] sm:hidden"></div>
    </form>
  </div>
</div>
