import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Subscription, timer } from 'rxjs';
import * as AuthSelectors from '../state/auth-state/auth.selectors';
import { HttpKnowledgeAnchoringService } from './http/http-knowledge-anchoring.service';
import { KnowledgeAnchoringPlanning } from '../../shared/models/entities/knowledge-anchoring.entity';
import { KnowledgeAnchoringPlannedRevisions } from '../../shared/models/enums/knowledge-anchoring.enums';
import { ModalService } from './modal.service';
import {
  ModalWarningData,
  ModalWarningComponent,
} from '../../shared/modals/modal-warning/modal-warning.component';

@Injectable({
  providedIn: 'root',
})
export class KnowledgeAnchoringService {
  checkTimeSubscription: Subscription;
  checkRecomputingStatusSubscription: Subscription;
  knowledgeAnchoringPlanning$ = new BehaviorSubject<KnowledgeAnchoringPlanning>(null);

  constructor(
    private store: Store,
    private modalService: ModalService,
    private httpKnowledgeAnchoringService: HttpKnowledgeAnchoringService
  ) {}

  init() {
    this.store.select(AuthSelectors.isLogged).subscribe((isLogged) => {
      if (isLogged) {
        this.checkTimeSubscription = timer(0, 5 * 60 * 1000).subscribe(() => {
          this.updateKnowledgeAnchoringPlanning();
        });
      } else {
        if (this.checkTimeSubscription) {
          this.checkTimeSubscription.unsubscribe();
        }
        if (this.checkRecomputingStatusSubscription) {
          this.checkRecomputingStatusSubscription.unsubscribe();
        }
        this.knowledgeAnchoringPlanning$.next(null);
      }
    });
  }

  updateKnowledgeAnchoringPlanning() {
    this.httpKnowledgeAnchoringService.getPlanning().subscribe((res) => {
      const planning = new KnowledgeAnchoringPlanning(res);
      this.knowledgeAnchoringPlanning$.next(planning);

      if (planning.anchoringBreak?.recomputing && !this.checkRecomputingStatusSubscription) {
        this.createCheckKnowledgeAnchoringRecomputingStatusSubscription();
      } else if (!planning.anchoringBreak?.recomputing && this.checkRecomputingStatusSubscription) {
        this.checkRecomputingStatusSubscription.unsubscribe();
      }
    });
  }

  createCheckKnowledgeAnchoringRecomputingStatusSubscription() {
    this.checkRecomputingStatusSubscription = timer(10 * 1000, 30 * 1000).subscribe(() => {
      this.updateKnowledgeAnchoringPlanning();
    });
  }

  substractRevision(plannedRevisions: KnowledgeAnchoringPlannedRevisions) {
    const planning = this.knowledgeAnchoringPlanning$.getValue();

    if (plannedRevisions === KnowledgeAnchoringPlannedRevisions.ONLY_LATE) {
      planning.totalRepetitionsLate--;
    } else if (plannedRevisions === KnowledgeAnchoringPlannedRevisions.ONLY_TODAY) {
      planning.totalRepetitionsPlannedToday--;
    } else {
      if (planning.totalRepetitionsLate > 0) {
        planning.totalRepetitionsLate--;
      } else {
        planning.totalRepetitionsPlannedToday--;
      }
    }

    this.knowledgeAnchoringPlanning$.next(planning);
  }

  anchoringIsOnBreakOrRecomputing() {
    const planning = this.knowledgeAnchoringPlanning$.getValue();
    if (!planning) return false;
    if (planning.anchoringBreak?.current) {
      const modalData: ModalWarningData = {
        title: $localize`Pause en cours !`,
        content: $localize`L’Ancrage des connaissances® est actuellement en pause. Tu dois arrêter cette dernière pour lancer une session.`,
        buttonText: $localize`OK`,
      };
      this.modalService.open(ModalWarningComponent, modalData);
      return true;
    }

    if (planning.anchoringBreak?.recomputing) {
      const modalData: ModalWarningData = {
        title: $localize`Replanification en cours !`,
        content: $localize`L’Ancrage des connaissances® est actuellement en cours de replanification suite à l'arrêt de la pause. Cela peut prendre quelques minutes.`,
        buttonText: $localize`OK`,
      };

      this.modalService.open(ModalWarningComponent, modalData);
      return true;
    }

    return false;
  }
}
